import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import * as React from "react"
import DesignIcon from "../../images/our-process/Design.svg"
import GrowthIcon from "../../images/our-process/Growth.svg"
import StrategyIcon from "../../images/our-process/Strategy.svg"
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import AspectRatioOutlinedIcon from "@material-ui/icons/AspectRatioOutlined"
import ImportantDevicesOutlinedIcon from "@material-ui/icons/ImportantDevicesOutlined"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Whatshot from "@material-ui/icons/Whatshot"
import Group from "@material-ui/icons/Group"
import Timeline from "@material-ui/icons/Timeline"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { graphql, PageProps } from "gatsby"


interface SaleForcePageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      iconName: string
      color?: string
    }
  }
}
const SaleForcePage : React.FC<PageProps<SaleForcePageData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, description } = frontmatter
  const feature = [
    {
      title:
        "Unified commerce experience across web, mobile, social, and in-store",
    },
    {
      title: "AI-powered personalization and predictive intelligence",
    },
    {
      title: "Scalable architecture to support high-volume transactions",
    },
    {
      title: "Robust API ecosystem for easy integrations",
    },
    {
      title: "Built-in analytics and reporting tools",
    },
  ]
  const experties = [
    {
      title: "Years of experience",
      content:
        "10+ years working with Salesforce Commerce Cloud (Demandware).  50+ successful implementations across various industries",
      imgSrc: DesignIcon,
    },
    {
      title: "Notable projects",
      content:
        "Helped a leading fashion retailer increase online sales by 35% through personalized product recommendations.  Implemented a unified commerce solution for a multi-brand consumer goods company, resulting in a 20% increase in cross-channel conversions",
      imgSrc: GrowthIcon,
    },
    {
      title: "Certifications and partnerships",
      content:
        "Salesforce Commerce Cloud Certified Implementation Partner.  Multiple team members with Salesforce Commerce Cloud Developer and Architect certifications",
      imgSrc: StrategyIcon,
    },
  ]
  const items = [
    {
      imgSrc: <ImportantDevicesOutlinedIcon />,
      title: "Development",
      desc: " Custom storefront design and development. Feature enhancements and extensions. Mobile-first responsive design implementation",
    },
    {
      imgSrc: <AspectRatioOutlinedIcon />,
      title: "Migration",
      desc: "Seamless migration from legacy platforms to Salesforce Commerce Cloud. Data migration and integrity assurance. Minimized downtime during transition.",
    },
    {
      imgSrc: <GroupOutlinedIcon />,
      title: " Support and maintenance",
      desc: "24/7 monitoring and support. Regular platform updates and patch management. Ongoing optimization and enhancements",
    },
    {
      imgSrc: <GroupWorkOutlinedIcon />,
      title: " Integration",
      desc: "Third-party system integrations (ERP, CRM, PIM, OMS). Payment gateway and shipping provider integrations. Custom API development and management",
    },
    {
      imgSrc: <TrendingUp />,
      title: "Optimization",
      desc: "Performance tuning and optimization. SEO enhancements. Conversion rate optimization (CRO).",
    },
  ]
  const faqData = [
    {
      question:
        "How long does a typical Salesforce Commerce Cloud implementation take?",
      answer:
        "Implementation timelines vary depending on project complexity, but generally range from 3-6 months for a basic setup to 6-12 months for more complex, enterprise-level implementations.",
    },
    {
      question:
        " Can Salesforce Commerce Cloud integrate with my existing systems?",
      answer:
        "Yes, Salesforce Commerce Cloud offers robust API capabilities, allowing for seamless integration with various third-party systems, including ERPs, CRMs, and other business-critical applications.",
    },
    {
      question:
        "How does Salesforce Commerce Cloud handle peak traffic during high-volume sales events?",
      answer:
        "Salesforce Commerce Cloud is built on a scalable cloud infrastructure that automatically adjusts to handle traffic spikes, ensuring optimal performance during high-volume events like Black Friday or product launches.",
    },
    {
      question: "What kind of post-launch support do you offer?",
      answer:
        "We provide comprehensive post-launch support, including 24/7 monitoring, regular maintenance, performance optimization, and ongoing enhancements to keep your e-commerce platform running smoothly and efficiently.",
    },
    {
      question:
        " How does Salesforce Commerce Cloud support omnichannel retail strategies?",
      answer:
        "Salesforce Commerce Cloud offers unified commerce capabilities, allowing businesses to create seamless experiences across online, mobile, social, and in-store channels, with features like order management, inventory visibility, and customer data unification.",
    },
  ]
  const helpItems = [
    {
      icon: <Whatshot />,
      title: "Unique selling points",
      content:
        "Proven track record of successful implementations. Deep understanding of e-commerce best practices and industry trends. Agile development methodology for faster time-to-market",
    },
    {
      icon: <Group />,
      title: "Team expertise",
      content:
        " Dedicated Salesforce Commerce Cloud specialists. Cross-functional team with expertise in UX/UI, backend development, and data analysis. Continuous learning and skill development programs",
    },
    {
      icon: <Timeline />,
      title: " Process highlights",
      content:
        "Thorough discovery and planning phase. Iterative development with regular client feedback. Comprehensive testing and quality assurance. Smooth deployment and post-launch support",
    },
  ]
  return (
    <>
      <Layout>
        <Seo
          title={title}
          description="Maximize your Salesforce Commerce Cloud potential. Our expert services drive growth and efficiency for your ecommerce business."
        />
        <div data-aos="fade-up">
          <PageHeading title={title} description={description}></PageHeading>
        </div>
        <div data-aos="fade-up">
          <PageIntro>
            {excerpt}
          </PageIntro>
        </div>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Platform Strengths</IHeading>
                </div>
                <div className="xl:w-2/4 lg:w-3/4" data-aos="fade-up">
                  <IParagraph>
                    Salesforce Commerce Cloud caters to mid-market and
                    enterprise-level businesses across various industries,
                    including retail, fashion, consumer goods, and
                    manufacturing.
                  </IParagraph>
                </div>
              </div>
              <div className="">
                <ILine align="left" />
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center" color="white">
                Our Expertise
              </IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="center" color="white">
                With years of dedicated experience in Magento development and
                optimization, our team has established a strong reputation in
                delivering high-performance e-commerce solutions.
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
              {experties.map((item: any, index: number) => {
                return (
                  <div
                    className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                      <img src={item.imgSrc} alt="dddd" />
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <IParagraph>{item.content}</IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Services Offered</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="px-16 space-y-3 list-disc">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-28">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">
                Why Choose Us for Salesforce Commerce Cloud
              </IHeading>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <IHeading size="subHeader" align="text-center z-10">
                      {item.title}
                    </IHeading>
                    <p className="absolute top-0 bottom-0 left-0 right-0 z-0 p-8 text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      <ul className="space-y-3 list-disc ">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Unlock Your E-commerce Potential with Salesforce Commerce Cloud"
          description="Ready to take your online business to the next level? Our Salesforce Commerce Cloud experts are here to guide you through every step of the journey, from implementation to optimization and beyond."
          cta="Get Started Today"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query SaleForcePageQuery {
    mdx(
      fileAbsolutePath: { regex: "/(technologies)/" }
      slug: { regex: "/(technologies/salesforce-commerce-cloud)/" }
    ) {
      excerpt(pruneLength: 1000)
      frontmatter {
        title
        iconName
        description
      }
    }
  }
`
export default SaleForcePage
